<template>
  <div id='get-to-know-section'>
    <div class="syscops__container">
      <div ref="contentWrapper"
        class="content-wrapper" :class="isVisible ? fadeInContentClass : fadeOutContentClass">
        <h1 class="title">{{ $t('sections.getToKnow.title') }}</h1>
        <p class="description">{{ $t('sections.getToKnow.description') }}</p>
        <base-button :title="$t('sections.getToKnow.button.text')" @clicked="handleBtnClick" />
      </div>
      <div v-if="isIllustrationsVisible" class="illustration-wrapper">
        <robot-w-question :class="isVisible ? 'fade-in' : 'fade-out'"/>
        <buildings :class="isVisible ? 'fade-in' : 'fade-out'"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

const RobotWQuestion = () => import('@/components/illustrations/RobotWQuestion.vue');
const Buildings = () => import('@/components/illustrations/Buildings.vue');

export default {
  name: 'GetToKnowSection',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
    RobotWQuestion,
    Buildings,
  },
  data: () => ({
    fadeInContentClass: 'animate__animated animate__fadeIn animate__slow',
    fadeOutContentClass: 'animate__animated animate__fadeOut animate__fast',
    isIllustrationsVisible: false,
  }),
  created() {},
  computed: {},
  watch: {
    isVisible(val) {
      if (!val) {
        setTimeout(() => {
          this.isIllustrationsVisible = val;
        }, 500);
      } else {
        this.isIllustrationsVisible = val;
      }
    },
  },
  methods: {
    handleBtnClick() {
      this.$emit('buttonClicked');
    },
  },
};
</script>

<style lang='scss' scoped>
#get-to-know-section {
  overflow: hidden;
}

.syscops__container {
  position: relative;
}

.content-wrapper {
  padding: 145px 0 0 125px;
  height: 610px; // content box height - 2 * 20px border
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  transition-delay: 0.5s;

  @media screen and (max-height: 759px) {
    padding-top: 100px;
    height: calc(100% - 20px);
  }

  @media screen and (max-width: 1299px) {
    padding-left: 90px;
  }
}

.title {
  @include sectionTitleStyle();
  max-width: 368px;
}

.description {
  @include sectionDescriptionStyle();
}

/*
  * Illustration keyframes
  *
*/
@keyframes robotAnimation-fadeIn {
  0% {
    opacity: 0;
    right: 0;
  }
  65% {
    opacity: 0.5;
    right: 33%;
  }
  100% {
    opacity: 1;
    right: 26%;
  }
}

@keyframes robotAnimation-fadeIn_responsive {
  0% {
    opacity: 0;
    right: 0;
  }
  65% {
    opacity: 0.5;
    right: 33%;
  }
  100% {
    opacity: 1;
    right: 15%;
  }
}

@keyframes robotAnimation-fadeOut {
  0% {
    opacity: 1;
    right: 26%;
  }
  100% {
    opacity: 0;
    right: 0;
  }
}

@keyframes robotAnimation-fadeOut_responsive {
  0% {
    opacity: 1;
    right: 15%;
  }
  100% {
    opacity: 0;
    right: 0;
  }
}

@keyframes buildingsAnimation-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes buildingsAnimation-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#robot-with-question {
  position: absolute;
  top: 70px;
  opacity: 0;
  right: 26%;
  width: 350px;
  z-index: 1;

  @media screen and (max-height: 759px) {
    width: 300px;
  }

  @media screen and (max-width: 1299px) {
    width: 300px;
    right: 15%;
  }

  &.fade-in {
    animation: robotAnimation-fadeIn 1.2s ease-in-out forwards;
    animation-delay: 0.9s;

    @media screen and (max-width: 1299px) {
      animation: robotAnimation-fadeIn_responsive 1.2s ease-in-out forwards;
      animation-delay: 0.9s;
    }
  }

  &.fade-out {
    animation: robotAnimation-fadeOut 0.5s ease-in-out;

    @media screen and (max-width: 1299px) {
      animation: robotAnimation-fadeOut_responsive 0.5s ease-in-out;
    }
  }
}

#buildings {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 33%;
  margin-top: 35px;
  z-index: 0;
  opacity: 0;

  @media screen and (max-width: 1299px) {
    width: 280px;
  }

  &.fade-in {
    animation: buildingsAnimation-fadeIn 1s ease-in-out forwards;
    animation-delay: 0.8s;
  }

  &.fade-out {
    animation: buildingsAnimation-fadeOut 0.5s ease-in-out;
  }
}
</style>
