<template>
  <div id='test-and-monitor-section'>
    <div class="syscops__container">
      <div class="content-wrapper" :class="isVisible ? fadeInContentClass : fadeOutContentClass">
        <h1 class="title">{{ $t('sections.testAndMonitor.title') }}</h1>
        <p class="description">{{ $t('sections.testAndMonitor.description') }}</p>
        <base-button :title="$t('sections.testAndMonitor.button.text')" @clicked="handleBtnClick" />
      </div>

      <div v-if="isIllustrationsVisible" class="illustration-wrapper">
        <robot-w-wave :class="isVisible ? 'fade-in' : 'fade-out'"/>
        <buildings-w-route :class="isVisible ? 'fade-in' : 'fade-out'"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

const RobotWWave = () => import('@/components/illustrations/RobotWWave.vue');
const BuildingsWRoute = () => import('@/components/illustrations/BuildingsWRoute.vue');

export default {
  name: 'TestMonitorSection',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
    RobotWWave,
    BuildingsWRoute,
  },
  data: () => ({
    fadeInContentClass: 'animate__animated animate__fadeIn animate__fast',
    fadeOutContentClass: 'animate__animated animate__fadeOut animate__fast',
    isIllustrationsVisible: false,
  }),
  created() {},
  computed: {},
  watch: {
    isVisible(val) {
      if (!val) {
        setTimeout(() => {
          this.isIllustrationsVisible = val;
        }, 1000);
      } else {
        this.isIllustrationsVisible = val;
      }
    },
  },
  methods: {
    handleBtnClick() {
      this.$emit('buttonClicked');
    },
  },
};
</script>

<style lang='scss' scoped>
.syscops__container {
  display: flex;
  justify-content: flex-end;
}

.content-wrapper {
  padding: 145px 170px 0 0;

  @media screen and (max-height: 759px) {
    padding-top: 115px;
  }
}

.title {
  @include sectionTitleStyle();
  max-width: 440px;
}

.description {
  @include sectionDescriptionStyle();
}

/*
  * Illustration keyframes
  *
*/
@keyframes robotAnimation-fadeIn {
  0% {
    opacity: 0;
    left: -220px;
  }
  65% {
    opacity: 0.8;
    left: 50px;
  }
  100% {
    opacity: 1;
    left: -60px;
  }
}

@keyframes robotAnimation-fadeIn_responsive {
  0% {
    opacity: 0;
    left: -120px;
  }
  65% {
    opacity: 0.8;
    left: 20px;
  }
  100% {
    opacity: 1;
    left: 0px;
  }
}

@keyframes robotAnimation-fadeOut {
  0% {
    opacity: 1;
    left: -60px;
  }
  100% {
    opacity: 0;
    left: -200px;
  }
}

@keyframes robotAnimation-fadeOut_responsive {
  0% {
    opacity: 1;
    left: 0px;
  }
  100% {
    opacity: 0;
    left: -200px;
  }
}

#robot-w-wave {
  position: absolute;
  top: 120px;
  opacity: 0;
  left: -60px;
  width: 330px;
  z-index: 1;

  @media screen and (max-height: 759px) {
    width: 280px;
    top: 100px;
  }

  @media screen and (max-width: 1299px) {
    left: 0;
    width: 300px;
  }

  &.fade-in {
    animation: robotAnimation-fadeIn 1.2s ease-in-out forwards;
    animation-delay: 0.9s;

    @media screen and (max-width: 1299px) {
      animation: robotAnimation-fadeIn_responsive 1.2s ease-in-out forwards;
      animation-delay: 0.9s;
    }
  }

  &.fade-out {
    animation: robotAnimation-fadeOut 0.5s ease-in-out;

    @media screen and (max-width: 1299px) {
      animation: robotAnimation-fadeOut_responsive 0.5s ease-in-out;
    }
  }
}

@keyframes buildingsAnimation-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes buildingsAnimation-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#buildings-w-route {
  position: absolute;
  top: 240px;
  left: 9.5%;
  transform: translateY(-50%);
  width: 36%;
  height: auto;
  opacity: 0;
  margin: 80px 0 0;

  @media screen and (max-height: 759px) {
    width: 32%;
  }

  @media screen and (max-width: 1299px) {
    width: 30%;
    top: 220px;
    left: 13%;
  }

  &.fade-in {
    animation: buildingsAnimation-fadeIn 1s ease-in-out forwards;
    animation-delay: 0.8s;
  }

  &.fade-out {
    animation: buildingsAnimation-fadeOut 0.5s ease-in-out;
  }
}
</style>
