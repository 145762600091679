<template>
  <div id='scroll-down'>
    <button class="button" @click="handleClick">
      <div class="button__icon">
        <span class="triangle" />
        <span class="circle" />
      </div>
      <span class="button__text">
        {{ $t('general.scrollDown.text') }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ScrollDown',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes text {
  0% {
    left: 0;
  }
  60% {
    left: 20px;
  }
  90% {
    left: 0;
  }
}

@keyframes circle {
  0% {
    left: 0;
  }
  60% {
    left: 25px;
  }
  80% {
    left: 0;
  }
}

@keyframes triangle {
  0% {
    left: 0;
  }
  60% {
    left: 15px;
  }
  70% {
    left: 0;
  }
}

#scroll-down {
  width: 185px;
  height: 22px;
  transform: rotate(-90deg);
  animation: button 1.7s 0s linear infinite;
}

button.button {
  cursor: pointer;
  background: transparent;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
}

.button__text {
  position: relative;
  font-family: 'Montserrat-Bold';
  font-size: 18px;
  display: inline-block;
  color: $purple-light;
  margin: 0 0 0 20px;
  animation: text 2s cubic-bezier(0.64, -0.21, 0.35, 1.15) infinite;
}

.button__icon {
  display: flex;
  align-items: center;

  .triangle {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 16px 7px 0;
    border-color: transparent $purple-light transparent transparent;
    margin: 0 0 0 0;
    animation: triangle 2s cubic-bezier(0.64, -0.21, 0.35, 1.15) infinite;
  }

  .circle {
    position: relative;
    width: 10px;
    height: 10px;
    background-color: $purple-light;
    border-radius: 100%;
    display: inline-block;
    animation: circle 2s cubic-bezier(0.64, -0.21, 0.35, 1.15) infinite;
  }
}
</style>
