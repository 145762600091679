<template>
  <div id='social-links'>
    <ul class="list">
      <li
        v-for="(item, i) in config" :key="i"
        class="list__item"
        @click="handleClick(item.link)">
        <span class="icon" :style="`mask-image: url(${item.iconUrl})`" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SocialLinks',
  props: {
    config: {
      type: Array,
      required: true,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick(link) {
      window.open(link);
    },
  },
};
</script>

<style lang='scss' scoped>
.list {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.list__item {
  cursor: pointer;
  height: 32px;
  display: flex;
  align-items: center;
  margin: 0 20px;

  @media screen and (max-width: 575px) {
    margin: 0 15px;
  }

  &:hover {
    .icon {
      background-color: $white;
    }
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 1;
  background-color: $purple-middle;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
  transition: $global-transition;
}
</style>
