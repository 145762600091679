<template>
  <div id="base-wrapper">
    <div v-if="!isMobile" id='landing-screen'>
      <div class="syscops__container">
        <div ref="contentBorderBox"
          :class="`content-box content-box--${currentPageIndex} ${tempAnimationClass}`" />
      </div>
      <div v-if="pages" class="fullpage-container" :class="`current-page-${currentPageIndex}`">
        <div class="fullpage-wp" ref="fullpage" v-fullpage="opts">
          <div v-for="n in pages.length" :key="n" :class="`page page-${n}`">
            <component
              v-bind:is="pages[n-1].bind.is"
              v-bind="pages[n-1].bind"
              v-on="pages[n-1].on"
            />
          </div>
        </div>
      </div>

      <transition
        enter-active-class="animate__animated animate__fadeInUp animate__slow"
        leave-active-class="animate__animated animate__fadeOutDown animate__slow"
      >
        <div v-if="currentPageIndex === pages.length - 1" >
          <scroll-to-top class="scroll-top-button" @clicked="goToWelcomeSection()" />
        </div>
      </transition>
      <transition
        enter-active-class="animate__animated animate__fadeInDown animate__slow"
        leave-active-class="animate__animated animate__fadeOutUp animate__slow"
      >
        <div v-if="currentPageIndex !== pages.length - 1">
          <scroll-down class="scroll-down-button" @clicked="navigateSection(1)" />
        </div>
      </transition>
    </div>
    <landing-screen-mobile v-else />
  </div>
</template>

<script>
import ScrollDown from '@/components/shared/ScrollDown.vue';
import ScrollToTop from '@/components/shared/ScrollToTop.vue';

import WelcomeSection from '@/screens/sections/WelcomeSection.vue';
import GetToKnowSection from '@/screens/sections/GetToKnowSection.vue';
import PersonalizeSection from '@/screens/sections/PersonalizeSection.vue';
import CreateInfrastructureSection from '@/screens/sections/CreateInfrastructureSection.vue';
import TestMonitorSection from '@/screens/sections/TestMonitorSection.vue';
import ServicesSection from '@/screens/sections/ServicesSection.vue';
// import AboutSection from '@/screens/sections/AboutSection.vue';
import ContactSection from '@/screens/sections/ContactSection.vue';

const LandingScreenMobile = () => import('@/screens/LandingScreenMobile.vue');

export default {
  name: 'LandingScreen',
  props: {},
  components: {
    WelcomeSection,
    GetToKnowSection,
    PersonalizeSection,
    ScrollDown,
    ScrollToTop,
    LandingScreenMobile,
  },
  data() {
    return {
      tempAnimationClass: undefined,
      currentPageIndex: 0,
      isChanging: false,
      prevPageIndex: 0,
      opts: {
        start: 0,
        disabled: false,
        overflow: 'scroll',
        direction: 'v',
        beforeChange: (currentSlideEl, currenIndex, nextIndex) => {
          this.isChanging = true;
          this.prevPageIndex = this.currentPageIndex;
          this.currentPageIndex = nextIndex;
        },
        afterChange: () => {
          this.isChanging = false;
        },
      },
      isMobile: false,
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.$eventBus.$on('disableScroll', this.disableScroll);
    this.$eventBus.$on('allowScroll', this.allowScroll);
    this.$eventBus.$on('navbarLogoClicked', this.goToWelcomeSection);
    this.$eventBus.$on('goToLastSection', this.goToContactSection);
  },
  computed: {
    pages() {
      return [
        {
          bind: {
            is: WelcomeSection,
            isVisible: this.currentPageIndex === 0,
          },
          on: {
            switchToNextSection: this.goToNextSection,
            buttonClicked: this.goToContactSection,
          },
        },
        {
          bind: {
            is: GetToKnowSection,
            isVisible: this.currentPageIndex === 1,
          },
          on: {
            buttonClicked: this.goToContactSection,
          },
        },
        {
          bind: {
            is: PersonalizeSection,
            isVisible: this.currentPageIndex === 2,
          },
          on: {
            buttonClicked: this.goToContactSection,
          },
        },
        {
          bind: {
            is: CreateInfrastructureSection,
            isVisible: this.currentPageIndex === 3,
          },
          on: {
            buttonClicked: this.goToContactSection,
          },
        },
        {
          bind: {
            is: TestMonitorSection,
            isVisible: this.currentPageIndex === 4,
          },
          on: {
            buttonClicked: this.goToContactSection,
          },
        },
        {
          bind: {
            is: ServicesSection,
            isVisible: this.currentPageIndex === 5,
            isCameFromNextSection: this.prevPageIndex === 6,
          },
          on: {
            switchToNextSection: this.goToNextSection,
            switchToPrevSection: this.goToPrevSection,
            updateContentBoxWidth: this.updateContenBorderBoxSize,
            buttonClicked: this.goToContactSection,
          },
        },
        // {
        //   bind: {
        //     is: AboutSection,
        //     isVisible: this.currentPageIndex === 6,
        //     isCameFromPrevSection: this.prevPageIndex === 5,
        //   },
        // },
        {
          bind: {
            is: ContactSection,
            isVisible: this.currentPageIndex === 6,
          },
        },
      ];
    },
  },
  watch: {
    currentPageIndex(newValue, oldValue) {
      if (newValue === 1 && oldValue === 2) {
        this.tempAnimationClass = 'get-to-know-animation';
      } else if (newValue === 4 && oldValue === 5) {
        this.tempAnimationClass = 'reverse';
      } else if (newValue === 5 && oldValue === 6) {
        this.tempAnimationClass = 'reverse';
      } else {
        this.tempAnimationClass = undefined;
      }

      switch (this.currentPageIndex) {
        case 0: {
          this.opts.overflow = 'scroll';
          break;
        }
        case 5: {
          this.$refs.fullpage.$fullpage.setDisabled(true);
          this.opts.overflow = 'scroll';
          break;
        }
        default:
          this.$refs.fullpage.$fullpage.setDisabled(false);
          this.opts.overflow = 'auto';
          break;
      }
    },
  },
  methods: {
    disableScroll() {
      this.$refs.fullpage.$fullpage.setDisabled(true);
    },
    allowScroll() {
      this.$refs.fullpage.$fullpage.setDisabled(false);
    },
    goToNextSection() {
      this.opts.overflow = 'auto';
      this.allowScroll();
    },
    goToPrevSection() {
      this.$refs.fullpage.$fullpage.moveTo(this.currentPageIndex - 1, true, true);
    },
    updateContenBorderBoxSize(width) {
      if (width) {
        this.$refs.contentBorderBox.style.width = width;
      } else {
        this.$refs.contentBorderBox.style.width = null;
      }
    },
    navigateSection(value) {
      this.$refs.fullpage.$fullpage.moveTo(this.currentPageIndex + value, true, true);
    },
    handleResize() {
      if (window.innerWidth < 992) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
        this.$nextTick(() => {
          this.goToWelcomeSection();
        });
      }
    },
    goToContactSection() {
      this.$refs.fullpage.$fullpage.moveTo(6, true, true);
    },
    goToWelcomeSection() {
      this.$refs.fullpage.$fullpage.moveTo(0, true, true);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang='scss' scoped>
/**
  * Content border box animations
*/
#landing-screen {
  --contentBoxWidth: 745px;
  --contentBoxDefaultHeight: 650px;

  @media screen and (max-height: 899px) {
    --contentBoxDefaultHeight: 600px;
  }
}

@keyframes contentBoxAnim__item-1 {
  50% {
    transform: scaleX(0.3);
    transform-origin: right top;
    border-left-width: calc(20px * 3);
    border-right-width: calc(20px * 3);
  }
  100% {
    width: 745px;
  }
}

@keyframes contentBoxAnim__item-2 {
  0% {
    width: 745px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 745px;
  }
}

@keyframes contentBoxAnim__item-3 {
  50% {
    transform: scaleX(0.3);
    transform-origin: right top;
    border-left-width: calc(20px * 3);
    border-right-width: calc(20px * 3);
  }
  100% {
     transform: scaleX(1);
  }
}

@keyframes contentBoxAnim__item-4 {
  0% {
    width: 745px;
  }
  50% {
    width: 250px;
  }
  100% {
    width: 745px;
  }
}

@keyframes contentBoxAnim__item-4--reverse {
  0% {
    top: 0;
    left: 0;
    width: 800px;
    height: 200px;
  }
  50% {
    width: 300px;
    height: 200px;
    left: calc(100% - #{var(--contentBoxWidth)});
  }
  100% {
    width: var(--contentBoxWidth);
    height: var(--contentBoxDefaultHeight);
  }
}

@keyframes contentBoxAnim__item-5 {
  0% {
    top: 0;
    left: calc(100% - #{var(--contentBoxWidth)});
    width: var(--contentBoxWidth);
    height: var(--contentBoxDefaultHeight);
  }
  50% {
    width: 250px;
    height: 200px;
    left: 50%;
  }
  100% {
    left: 0;
    width: 800px;
  }
}

@keyframes contentBoxAnim__item-5--reverse {
  0% {
    width: 460px;
    height: 645px;
    top: 0;
    left: 90px;
  }
  50% {
    width: 460px;
    height: 200px;
    top: 0;
  }
  100% {
    width: 800px;
    left: 0;
  }
}

@keyframes contentBoxAnim__item-5--reverse_responsive {
  0% {
    width: 460px;
    height: 645px;
    top: 0;
    left: calc((100% - 2 * 470px) / 2);
  }
  50% {
    width: 460px;
    height: 200px;
    top: 0;
  }
  100% {
    width: 800px;
    left: 0;
  }
}

/**
  * Content border box config
  *
*/
.content-box {
  position: absolute;
  transition: all 0.5s ease-in-out;
  border: 20px solid rgba($purple, 0.3);

  &--7 {
    transition-delay: 0.3s;
  }
}

.content-box {
  @media screen and (max-height: 759px) {
    &:not(.content-box--5) {
      height: calc(100% - 20px) !important;
    }
  }

  &--0,
  &--1 {
    top: 0;
    left: 0;
    width: var(--contentBoxWidth);
    height: var(--contentBoxDefaultHeight);
  }

  &--1.get-to-know-animation {
    animation: contentBoxAnim__item-1 0.8s ease-in-out;
  }

  &--2 {
    top: 0;
    left: calc(100% - #{var(--contentBoxWidth)});
    width: var(--contentBoxWidth);
    height: var(--contentBoxDefaultHeight);
    animation: contentBoxAnim__item-2 0.8s ease-in-out ;
  }

  &--3 {
    top: 0;
    left: 0;
    width: var(--contentBoxWidth);
    height: var(--contentBoxDefaultHeight);
    animation: contentBoxAnim__item-3 0.8s ease-in-out;
  }

  &--4 {
    top: 0;
    left: calc(100% - #{var(--contentBoxWidth)});
    width: var(--contentBoxWidth);
    height: var(--contentBoxDefaultHeight);
    animation: contentBoxAnim__item-4 0.8s ease-in-out ;

    &.reverse {
      animation: contentBoxAnim__item-4--reverse 0.8s ease-in-out;
    }
  }

  &--5 {
    top: 0;
    left: 0;
    width: 800px;
    height: 200px;
    animation: contentBoxAnim__item-5 0.8s ease-in-out ;

    &.reverse {
      animation: contentBoxAnim__item-5--reverse 0.8s ease-in-out;

      @media screen and (max-width: 1299px) {
        animation: contentBoxAnim__item-5--reverse_responsive 0.8s ease-in-out;
      }
    }
  }

  // &--6 {
  //   width: 460px;
  //   height: 645px;
  //   top: 0;
  //   left: 90px;

  //   @media screen and (max-width: 1299px) {
  //     left: calc((100% - 2 * 470px) / 2);
  //   }

  //   @media screen and (max-height: 899px) {
  //     height: 600px;
  //   }
  // }

  &--6 {
    width: 830px;
    height: 600px;
    top: 0;
    left: 90px;

    @media screen and (max-width: 1299px) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

#base-wrapper {
  height: calc(100% - 120px); // navbar's height

  @media screen and (max-height: 759px) {
    height: calc(100% - 80px);
  }

  @media screen and (max-width: 991px) {
    height: 100%;
  }
}

#landing-screen {
  height: 100%;
}

.syscops__container {
  height: calc(100% - 120px); // navbar's height
  top: 120px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 40px;

  @media screen and (max-height: 759px) {
    top: 80px;
  }

  @media screen and (max-width: 1299px) {
    width: calc(100% - 60px);
    padding: 0;
    left: 30px;
    transform: none;
    margin-left: 0;
  }
}

.fullpage-wp.anim {
  transition: all 0.8s ease-out 0s;
  transition-delay: 0.4s;
}

.scroll-top-button,
.scroll-down-button {
  position: fixed;
  left: -5px;
  bottom: 135px;
}

.scroll-down-button {
  @media screen and (max-width: 1399px) {
    left: -45px;
  }

  @media screen and (max-width: 1299px) {
    left: -77px;
  }
}

.scroll-top-button {
  left: 30px;

  @media screen and (max-width: 1399px) {
    left: -10px;
  }

  @media screen and (max-width: 1299px) {
    display: none;
  }
}
</style>
