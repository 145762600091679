<template>
  <div id='create-infrastructure-section'>
    <div class="syscops__container">
      <div class="content-wrapper" :class="isVisible ? fadeInContentClass : fadeOutContentClass">
        <h1 class="title">{{ $t('sections.createInfrastructure.title') }}</h1>
        <p class="description">{{ $t('sections.createInfrastructure.description') }}</p>
        <base-button :title="$t('sections.createInfrastructure.button.text')"
          @clicked="handleBtnClick" />
      </div>

      <div  v-if="isIllustrationsVisible" class="illustration-wrapper">
        <robot-w-box :class="isVisible ? 'fade-in' : 'fade-out'"/>
        <buildings-shapes :class="isVisible ? 'fade-in' : 'fade-out'"/>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

const RobotWBox = () => import('@/components/illustrations/RobotWBox.vue');
const BuildingsShapes = () => import('@/components/illustrations/BuildingsShapes.vue');

export default {
  name: 'CreateInfrastructureSection',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
    RobotWBox,
    BuildingsShapes,
  },
  data: () => ({
    fadeInContentClass: 'animate__animated animate__fadeIn animate__fast',
    fadeOutContentClass: 'animate__animated animate__fadeOut animate__fast',
    isIllustrationsVisible: false,
  }),
  created() {},
  computed: {},
  watch: {
    isVisible(val) {
      if (!val) {
        setTimeout(() => {
          this.isIllustrationsVisible = val;
        }, 500);
      } else {
        this.isIllustrationsVisible = val;
      }
    },
  },
  methods: {
    handleBtnClick() {
      this.$emit('buttonClicked');
    },
  },
};
</script>

<style lang='scss' scoped>
.syscops__container {
  position: relative;
}

.content-wrapper {
  padding: 120px 0 0 125px;

  @media screen and (max-height: 759px) {
    padding-top: 100px;
  }
}

.title {
  @include sectionTitleStyle();
  max-width: 405px;
}

.description {
  @include sectionDescriptionStyle(0 0 105px);
}

/*
  * Illustration keyframes
  *
*/
@keyframes robotAnimation-fadeIn {
  0% {
    opacity: 0;
    right: 0;
  }
  65% {
    opacity: 0.5;
    right: 33%;
  }
  100% {
    opacity: 1;
    right: 26%;
  }
}

@keyframes robotAnimation-fadeIn_responsive {
  0% {
    opacity: 0;
    right: 0;
  }
  65% {
    opacity: 0.5;
    right: 33%;
  }
  100% {
    opacity: 1;
    right: 15%;
  }
}

@keyframes robotAnimation-fadeOut {
  0% {
    opacity: 1;
    right: 26%;
  }
  100% {
    opacity: 0;
    right: 0;
  }
}

@keyframes robotAnimation-fadeOut_responsive {
  0% {
    opacity: 1;
    right: 15%;
  }
  100% {
    opacity: 0;
    right: 0;
  }
}

#robot-w-box {
  position: absolute;
  top: 70px;
  opacity: 0;
  right: -60px;
  width: 350px;
  z-index: 1;

  @media screen and (max-height: 759px) {
    width: 300px;
    top: 40px;
  }

  @media screen and (max-width: 1299px) {
    width: 300px;
    right: 15%;
  }

  &.fade-in {
    animation: robotAnimation-fadeIn 1.2s ease-in-out forwards;
    animation-delay: 0.9s;

    @media screen and (max-width: 1299px) {
      animation: robotAnimation-fadeIn_responsive 1.2s ease-in-out forwards;
      animation-delay: 0.9s;
    }
  }

  &.fade-out {
    animation: robotAnimation-fadeOut 0.5s ease-in-out;

    @media screen and (max-width: 1299px) {
      animation: robotAnimation-fadeOut_responsive 0.5s ease-in-out;
    }
  }
}

@keyframes buildingsAnimation-fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes buildingsAnimation-fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#buildings-shapes {
  position: absolute;
  top: 50%;
  right: -11.5%;
  transform: translateY(-50%);
  width: 50%;
  margin-top: 65px;
  z-index: 0;
  opacity: 0;

  @media screen and (max-height: 759px) {
    // width: 40%;
  }

  @media screen and (max-width: 1299px) {
    width: 35%;
    right: 0;
  }

  &.fade-in {
    animation: buildingsAnimation-fadeIn 1s ease-in-out forwards;
    animation-delay: 0.8s;
  }

  &.fade-out {
    animation: buildingsAnimation-fadeOut 0.5s ease-in-out;
  }
}
</style>
