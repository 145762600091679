<template>
  <div id='drag-drop-capcha'>
      <div :class="['draggable', { 'verified': isVerified }]"
        ref="draggableContainer"  @mousedown="dragMouseDown">
        <robot-w-envelope :class="{ 'animate__animated animate__shakeX': shakeRobot }" />
      </div>
      <div ref="target" class="target">
        <div class="target__illustration">
          <img src="/images/components/capcha/placeholder.png" class="illustration__image">
          <span class="illustration__text">{{ $t('general.capcha.target.text') }}</span>
        </div>
      </div>
  </div>
</template>

<script>
const RobotWEnvelope = () => import('@/components/illustrations/RobotWEnvelope.vue');

export default {
  name: 'DragDropCapcha',
  props: {},
  components: {
    RobotWEnvelope,
  },
  data: () => ({
    positions: {
      clientX: undefined,
      clientY: undefined,
      movementX: 0,
      movementY: 0,
      droppedY: undefined,
      droppedX: undefined,
    },
    isVerified: false,
    shakeRobot: false,
  }),
  created() {
    this.$eventBus.$on('shakeCapchaRobot', () => {
      this.shakeRobot = true;
    });
  },
  watch: {
    shakeRobot(val) {
      if (val) {
        setTimeout(() => {
          this.shakeRobot = false;
        }, 2000);
      }
    },
  },
  computed: {},
  methods: {
    dragMouseDown(event) {
      event.preventDefault();
      this.$emit('moved');
      if (!this.isVerified) {
        this.$eventBus.$emit('disableScroll');
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX;
        this.positions.clientY = event.clientY;
        document.onmousemove = this.elementDrag;
        document.onmouseup = this.closeDragElement;
      }
    },
    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.movementY = this.positions.clientY - event.clientY;
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.droppedY = this.$refs.draggableContainer.offsetTop - this.positions.movementY;
      this.droppedX = this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
      this.$refs.draggableContainer.style.top = `${this.droppedY}px`;
      this.$refs.draggableContainer.style.left = `${this.droppedX}px`;
    },
    closeDragElement() {
      if (
        this.droppedY <= 100 && this.droppedY >= -100
        && this.droppedX <= 100 && this.droppedX >= -100
      ) {
        this.isVerified = true;
        this.$emit('verified');
        this.$eventBus.$emit('allowScroll');
      } else {
        this.$eventBus.$emit('allowScroll');
        this.isVerified = false;
      }
      document.onmouseup = null;
      document.onmousemove = null;
    },
    resetDraggable() {
      this.$refs.draggableContainer.style.top = '75px';
      this.$refs.draggableContainer.style.left = '-270px';

      if (window.innerHeight <= 899 && window.innerWidth <= 1440) {
        this.$refs.draggableContainer.style.left = '-190px';
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes targetBgAnimation {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes targetTextAnimation {
  0% {
    bottom: 175px;
  }
  30% {
    bottom: 185px;
  }
  70% {
    bottom: 195px;
  }
  100% {
    bottom: 175px;
  }
}

#drag-drop-capcha {
  position: relative;
}

#robot-w-envelope {
  width: 290px;
}

.draggable {
  position: absolute;
  left: -270px;
  top: 75px;
  z-index: 10;

  @media screen and (max-height: 899px) and (max-width: 1440px) {
    left: -190px;
  }

  &.verified {
    transition: all 0.4s ease-in-out;
    top: 70px !important;
    left: -30px !important;
  }
}

.target__illustration {
  position: relative;
}

.illustration__image {
  animation: targetBgAnimation 2s ease-in-out infinite;
}

.illustration__text {
  color: $global-secondary-font-color;
  font-size: 18px;
  font-family: 'Montserrat-Regular';
  display: inline-block;
  position: absolute;
  bottom: 175px;
  left: 40px;
  white-space: nowrap;
  transform: rotate(-90deg);
  animation: targetTextAnimation 2s ease-in-out infinite;
}
</style>
