<template>
  <div :class="['base-input', { 'invalid': hasError }]">
    <label>{{ labelText }}</label>
    <input ref="input" :name="name" :type="type" @input="handleInput">
  </div>
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    name: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    labelText: {
      type: String,
      required: false,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleInput(e) {
      this.$emit('updated', e.target.value);
    },
  },
};
</script>

<style lang='scss' scoped>
input {
  border: 10px solid $purple-middle;
  color: $white;
  font-family: 'AkzidenzGrotesk-Med';
  font-size: 13px;
  height: 60px;
  width: 100%;
  background: transparent;
  appearance: none;
  padding: 0 20px;
  transition: $global-transition;

  .invalid & {
    border-color: $error-color;
  }

  &:focus {
    outline: none;
  }
}

label {
  display: block;
  line-height: 28px;
  text-transform: uppercase;
  font-size: 18px;
  margin: 0 0 10px;
  font-family: 'Montserrat-Regular';

  @media screen and (max-width: 575px) {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
