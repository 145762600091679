<template>
  <div class="base-button"
    :class="[{ 'hovered': isHovering }, { 'loading': isLoading }]"
    :style="`--width: ${width}px; --height: ${height}px;`"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false">
    <button @click="handleButtonClick" :type="type">
      <template v-if="!isLoading">
        <span class="base-button__hover-text top first">{{ title }}</span>
        <span class="base-button__hover-text top second">{{ title }}</span>
        <span class="base-button__main-text">{{ title }}</span>
        <span class="base-button__hover-text bottom first">{{ title }}</span>
        <span class="base-button__hover-text bottom second">{{ title }}</span>
      </template>
      <template v-else>
        <span class="loader-icon" :style="{maskImage: 'url(/images/icons/loader.svg)'}"/>
      </template>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    title: {
      type: String,
    },
    width: {
      type: Number,
      required: false,
      default: 326,
    },
    height: {
      type: Number,
      required: false,
      default: 70,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {},
  data: () => ({
    isHovering: false,
  }),
  created() {},
  computed: {},
  methods: {
    handleButtonClick() {
      this.$emit('clicked');

      setTimeout(() => {
        this.isHovering = false;
      }, 500);
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes loaderRing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.base-button {
  display: inline-block;
  width: var(--width);
  height: var(--height);
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &:active,
  &:focus {
    transform: none;
  }

  &.loading {
    &.hovered {
      transform: none;
    }

    .loader-icon {
      display: inline-block;
      width: 26px;
      height: 26px;
      mask-position: center;
      background-color: $white;
      mask-repeat: no-repat;
      mask-size: cover;
      animation: loaderRing 1.5s linear infinite;
    }
  }

  &.hovered {
    transform: scale(1, 1.15);

    .base-button__main-text {
      color: $black;
      transform: scale(1, 0.85);
    }

    .base-button__hover-text {
      transform: scale(1, 0.85);
    }

    .base-button__hover-text {
      &.top {
        &.first {
          top: -4px;
          opacity: 0.2;
        }

        &.second {
          top: 11px;
          opacity: 0.5;
        }
      }

      &.bottom {
        &.first {
          bottom: 11px;
          opacity: 0.5;
        }

        &.second {
          bottom: -4px;
          opacity: 0.2;
        }
      }
    }
  }

  button {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0;
    background: $purple-light;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat-Bold';
    font-size: 18px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    position: relative;
    appearance: none;

    &:focus,
    &:active {
      outline: none;
    }
  }
}

.base-button__main-text {
  transition: all 0.2s ease-in-out;
}

.base-button__hover-text {
  position: absolute;
  transition: all 0.2s ease-in-out;

  &.top {
    &.first {
      top: 50%;
      opacity: 0;
    }

    &.second {
      top: 50%;
      opacity: 0;
    }
  }

  &.bottom {
    &.first {
      bottom: 50%;
      opacity: 0;
    }

    &.second {
      bottom: 50%;
      opacity: 0;
    }
  }
}
</style>
