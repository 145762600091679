<template>
  <div id='services-section'>
    <div class="syscops__container">
      <div ref="contentWrapper" class="content-wrapper"
      :class="[{ 'visible': isVisible }, { 'fadeOut': isFadeOutPrev },
        { 'fadeOutUp': isFadeOutPrevNext }, { 'fadeInReverse': isCameFromNextSection }]">
        <ul class="services-list" :style="`--currentElementIndex: ${scrollDownCounter}`">
          <div class="services-list__items-wrapper">
            <li v-for="(item, i) in services" :key="i"
              :class="['services-list__item',
              { 'services-list__item--active': i === scrollDownCounter }]">
              {{ item }}
            </li>
          </div>
        </ul>
        <div class="button__wrapper">
          <base-button :title="$t('sections.services.button.text')" @clicked="handleBtnClick" />
        </div>
        <div class="illustrations-list">
          <div v-for="n in 9" :key="n" :class="['animation-helper-wrapper',
            { 'use': n-1 === 7 || n-1 === 8 },
            { 'visible': n-1 <= scrollDownCounter }]">
            <img :src="`/images/section-illustrations/services/${n-1}.png`"
              :class="['illustration', `illustration-${n-1}`,
                { 'visible': n-1 <= scrollDownCounter },
                { 'hide': scrollDownCounter >= 3 && n-1 == 1 }]">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

export default {
  name: 'ServicesSection',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    isCameFromNextSection: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
    scrollCount: 0,
    scrollDown: undefined,
    scrollDownCounter: 0,
    isFadeOutPrev: false,
    isFadeOutPrevNext: false,
    isEmited: false,
    scrollDownTimeout: undefined,
  }),
  created() {
    this.$nextTick(() => {
      this.$refs.contentWrapper.addEventListener('wheel', this.handleScroll);
    });
  },
  watch: {
    isVisible(value) {
      if (value) {
        this.addScrollListener();
        this.isFadeOutPrev = false;
        this.isFadeOutPrevNext = false;
        this.isEmited = false;
      }
    },
    scrollDownCounter() {
      setTimeout(() => {
        this.addScrollListener();
      }, 1200);
    },
  },
  computed: {
    services() {
      return [
        this.$t('sections.services.cloudProvider'),
        this.$t('sections.services.networking'),
        this.$t('sections.services.servers'),
        this.$t('sections.services.firewall'),
        this.$t('sections.services.security'),
        this.$t('sections.services.monitoring'),
        this.$t('sections.services.backup'),
        this.$t('sections.services.deploy'),
        this.$t('sections.services.live'),
      ];
    },
  },
  methods: {
    addScrollListener() {
      this.$refs.contentWrapper.addEventListener('wheel', this.handleScroll);
    },
    removeScrollListener() {
      this.$refs.contentWrapper.removeEventListener('wheel', this.handleScroll);
    },
    handleScroll(e) {
      if (
        (e.deltaY < 0 && this.scrollDown)
        || (e.deltaY > 0 && !this.scrollDown)
      ) {
        this.scrollCount = 0;
      }

      if (this.scrollCount === 0) {
        e.preventDefault();

        if (this.scrollDownCounter === 8 && e.deltaY > 0) {
          this.isFadeOutPrevNext = true;
          if (!this.isEmited) {
            setTimeout(() => {
              this.$emit('switchToNextSection');
              this.isEmited = true;
            }, 730);
          }

          this.$emit('switchToNextSection');
        } else if (this.scrollDownCounter === 0 && e.deltaY < 0) {
          this.isFadeOutPrev = true;
          if (!this.isEmited) {
            setTimeout(() => {
              this.$emit('switchToPrevSection');
              this.isEmited = true;
            }, 730);
          }
        }

        if (e.deltaY < 0) {
          this.scrollCount = 1;
          this.scrollDown = false;

          this.scrollCount = 0;
          this.scrollDownCounter = this.scrollDownCounter > 0
            ? this.scrollDownCounter - 1 : this.scrollDownCounter;

          if (this.scrollDownCounter === 7) {
            this.$emit('updateContentBoxWidth', '870px');
          } else if (this.scrollDownCounter === 6) {
            this.$emit('updateContentBoxWidth');
          }
          this.removeScrollListener();
        } else if (e.deltaY > 0) {
          this.scrollCount = 1;
          this.scrollDown = true;

          this.scrollCount = 0;
          this.scrollDownCounter = this.scrollDownCounter < 8
            ? this.scrollDownCounter + 1 : this.scrollDownCounter;

          if (this.scrollDownCounter === 7) {
            this.$emit('updateContentBoxWidth', '870px');
          } else if (this.scrollDownCounter === 8) {
            this.$emit('updateContentBoxWidth');
          }
          this.removeScrollListener();
        }
      }
    },
    handleBtnClick() {
      this.$emit('buttonClicked');
    },
  },
  beforeDestroy() {
    this.$refs.contentWrapper.removeEventListener('wheel', this.handleScroll);
  },
};
</script>

<style lang='scss' scoped>
#services-section,
.syscops__container,
.content-wrapper  {
  height: 100%;
}

.content-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0px 0 0 0;
  opacity: 0;
  transition: all 0.8s ease-in-out;
  transition-delay: 0.4s;

  &.visible {
    opacity: 1;
  }

  &.fadeOut {
    opacity: 0;
    transition-delay: 0.4s;
  }

  &.fadeOutUp {
    transition-delay: 0s;
  }
}

.services-list {
  margin: 20px 0 0;
  padding: 0;
  list-style-type: none;
  height: 160px;
  width: 800px;
  overflow: hidden;
  position: relative;
  padding-top: 160px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.8s;

  .visible & {
    padding-top: 0;
  }

  .fadeOut & {
    padding-top: 160px;
    transition-duration: 0.4s;
    transition-delay: 0;
  }

  .fadeInReverse:not(.fadeOut) & {
    transition: none;
  }
}

.button__wrapper {
  height: 108px;
  overflow: hidden;
  margin-left: auto;
  margin: 70px 0 20px auto;
  padding-top: 160px;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.8s;

  @media screen and (max-width: 1299px) {
    display: none;
  }

  .visible & {
    padding-top: 0;
  }

  .fadeOut & {
    transition-duration: 0.4s;
    transition-delay: 0;
    padding-top: 160px;
  }

  .fadeInReverse:not(.fadeOut) & {
    transition: none;
  }
}

.services-list__items-wrapper {
  transition: all 0.8s ease-in-out;
  transform: translateY(calc(-1 * (var(--currentElementIndex) * 160px)));
}

.services-list__item {
  font-family: 'AkzidenzGrotesk-Bold';
  font-size: 48px;
  line-height: 160px;
  display: block;
  position: relative;
  padding: 0 0 0 75px;
  height: 160px;
  display: block;
  width: 100%;
  opacity: 0;
  transition: opacity 0.8s ease-out;

  &--active {
    opacity: 1;
  }
}

.animation-helper-wrapper {
  &.use {
    opacity: 0;
    transition: all 0.5s ease-in-out;

    &.visible {
      opacity: 1;
    }
  }
}

@keyframes fadeDownSlowly {
  0% {
    top: -200px;
  }
  100% {
    top: -20px;
  }
}

@keyframes fadeDownSlowly_lg {
  0% {
    top: -200px;
  }
  100% {
    top: -60px;
  }
}

@keyframes fadeDownSlowly_md {
  0% {
    top: -200px;
  }
  100% {
    top: -100px;
  }
}

.illustrations-list {
  width: 100%;
  position: relative;
  top: -20px;
  left: 200px;
  height: calc(100% - 200px);

  @media screen and (max-width: 1299px) {
    width: 750px;
    left: calc((100% - 750px) / 2);
  }

  @media screen and (max-height: 899px) {
    top: -60px;
    transform: scale(0.9);
  }

  @media screen and (max-height: 759px) {
    top: -100px;
    transform: scale(0.8);
    left: 180px;
  }

  .fadeInReverse & {
    animation: fadeDownSlowly 1.7s ease-in-out forwards;

    @media screen and (max-height: 899px) {
      animation: fadeDownSlowly_lg 1.7s ease-in-out forwards;
      transform: scale(0.9);
    }

    @media screen and (max-height: 759px) {
      animation: fadeDownSlowly_md 1.7s ease-in-out forwards;
      transform: scale(0.8);
    }
  }

  .illustration {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 430px;
    width: auto;
    top: 50px;
    transition: all 0.5s ease-in-out;

    &.hide {
      opacity: 0 !important;
    }

    &.visible {
      top: 0;
      opacity: 1;
    }

    &-1 {
      width: 764px;
      height: 467px;
      left: 25px;

      &.visible {
        top: 12px;
      }
    }

    &-2 {
      top: -20px;

      &.visible {
        top: 0px;
      }
    }

    &-3 {
      left: 24px;
      width: 764px;
      height: 467px;

      &.visible {
        top: 11px;
      }
    }

    &-4 {
      top: -50px;
      left: 1px;
      z-index: 1;

      &.visible {
        top: 1px;
      }
    }

    &-5 {
      top: -10px;
      left: 11px;
      z-index: 2;

    &.visible {
      top: 58px;
    }
   }

    &-6 {
      left: -17px;
      width: 753px;
      height: 567px;

      &.visible {
        top: -50px;
      }
    }

    @keyframes linesAnimation {
      0% {
        top: 30px;
        opacity: 0.2;
      }
      50% {
        top: 20px;
        opacity: 1;
      }
      100% {
        top: 30px;
        opacity: 0.2;
      }
    }

    &-7 {
      left: 30px;
      animation: linesAnimation 2.5s
        cubic-bezier(0.71, 0.01, 0.37, 1.01) infinite;

      &.visible {
        top: 30px;
        z-index: 2;
      }
    }

    @keyframes routeAnimation {
      0% {
        top: -29px;
        opacity: 0.2;
      }
      50% {
        top: -32px;
        opacity: 1;
      }
      100% {
        top: -29px;
        opacity: 0.2;
      }
    }

    &-8 {
      top: -50px;
      width: 835px;
      height: 545px;
      left: -10px;
      animation: routeAnimation 2.5s
        cubic-bezier(0.71, 0.01, 0.37, 1.01) infinite;

      &.visible {
        z-index: -1;
        top: -29px;
      }
    }
  }
}
</style>
