import { render, staticRenderFns } from "./DragDropCapcha.vue?vue&type=template&id=9b7d1348&scoped=true&"
import script from "./DragDropCapcha.vue?vue&type=script&lang=js&"
export * from "./DragDropCapcha.vue?vue&type=script&lang=js&"
import style0 from "./DragDropCapcha.vue?vue&type=style&index=0&id=9b7d1348&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b7d1348",
  null
  
)

export default component.exports