<template>
  <div id='contact-section'>
    <div class="syscops__container">
      <div class="content-wrapper">
        <div class="content__left-col" :class="{'content__left-col--fade' : isVisible}">
          <h1 class="title">{{ $t('sections.identity.title') }}</h1>
          <div class="contacts_container">
            <p>info@syscops.com</p>
            <p>+36 20 264 9536</p>
          </div>
          <div class="content__form">
            <div class="form__capcha">
              <drag-drop-capcha
                ref="capcha"
                @verified="handleCapchaVerify" />
            </div>
            <form ref="emailForm" class="form__fields" @submit.prevent="sendEmail">
              <base-input
                :name="'name'"
                ref="nameInput"
                :hasError="formInputs.name.hasError"
                :labelText="$t('sections.identity.form.labels.name')"
                @updated="handleFormInput('name', ...arguments)"
              />
              <base-input
                :name="'email'"
                ref="emailInput"
                :type="'email'"
                :hasError="formInputs.email.hasError"
                :labelText="$t('sections.identity.form.labels.email')"
                @updated="handleFormInput('email', ...arguments)"
              />
              <base-input
                :name="'message'"
                ref="messageInput"
                :hasError="formInputs.message.hasError"
                :labelText="$t('sections.identity.form.labels.message')"
                @updated="handleFormInput('message', ...arguments)"
              />
              <base-button
                :type="'submit'"
                :isLoading="formButtonIsLoading"
                :title="$t('sections.identity.form.button.title')"
                :width="350"
              />
            </form>
          </div>
        </div>

        <div class="content__right-col" :class="{'content__right-col--fade' : isVisible}">
          <span class="verify-id">{{ verifyId }}</span>
        </div>

      </div>
      <div class="copyright-row">
        <social-links :config="socialsConfig" />
        <span class="copyright">{{ $t('copyright.text') }}</span>
      </div>
    </div>
    <img src="/images/section-illustrations/contact_illustration.png" class="section-illustration">
  </div>
</template>

<script>
import emailjs from 'emailjs-com';
import BaseInput from '@/components/shared/elements/BaseInput.vue';
import BaseButton from '@/components/shared/BaseButton.vue';
import DragDropCapcha from '@/components/shared/DragDropCapcha.vue';
import SocialLinks from '@/components/shared/SocialLinks.vue';

export default {
  name: 'ContactSection',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseInput,
    BaseButton,
    DragDropCapcha,
    SocialLinks,
  },
  data: () => ({
    formInputs: {
      name: {
        value: undefined,
        hasError: false,
      },
      email: {
        value: undefined,
        hasError: false,
      },
      message: {
        value: undefined,
        hasError: false,
      }
    },
    formButtonIsLoading: false,
    socialsConfig: [
      {
        iconUrl: '/images/icons/github.svg',
        link: 'https://github.com',
      },
      {
        iconUrl: '/images/icons/fb.svg',
        link: 'https://facebook.com',
      },
      {
        iconUrl: '/images/icons/twitter.svg',
        link: 'https://twitter.com',
      },
      {
        iconUrl: '/images/icons/instagram.svg',
        link: 'https://instagram.com',
      },
    ],
    isCapchaVerified: false,
  }),
  created() {},
  computed: {
    verifyId() {
      return `0x${this.generateRandomHash(40)}`;
    },
  },
  watch: {
    isVisible(val) {
      if (val && !this.isCapchaVerified) {
        this.$refs.capcha.resetDraggable();
      }
    },
  },
  methods: {
    generateRandomHash(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    handleFormInput(fieldName, value) {
      switch (fieldName) {
        case 'name':
          this.formInputs.name.value = value;
          break;
        case 'email':
          this.formInputs.email.value = value;
          break;
        case 'message':
          this.formInputs.message.value = value;
          break;
        default:
          break;
      }
    },
    handleCapchaVerify() {
      this.isCapchaVerified = true;
    },
    validateForm() {
      // name validation
      this.formInputs.name.hasError = this.formInputs.name.value === undefined
        || this.formInputs.name.value.length === 0
        || this.formInputs.name.value.length < 5;

      // email validation
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.formInputs.email.hasError = this.formInputs.email.value === undefined
        || this.formInputs.email.value.length === 0
        || !re.test(this.formInputs.email.value);

      return this.formInputs.name.hasError && this.formInputs.email.hasError;
    },
    sendEmail(e) {
      const validateResult = this.validateForm();

      if (!validateResult && !this.isCapchaVerified) {
        this.$eventBus.$emit('shakeCapchaRobot');
      } else if (!validateResult && this.isCapchaVerified) {
        this.formButtonIsLoading = true;
        emailjs.sendForm(
          process.env.VUE_APP_EMAILJS_SERVICE_ID,
          process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
          e.target,
          process.env.VUE_APP_EMAILJS_USER_ID,
        )
          .then(() => {
            this.$eventBus.$emit('triggerNotification', this.$t('sections.identity.form.notification.success'));
            this.formButtonIsLoading = false;
            this.formInputs.name.value = undefined;
            this.formInputs.email.value = undefined;
            this.formInputs.message.value = undefined;
            this.$refs.nameInput.$refs.input.value = '';
            this.$refs.emailInput.$refs.input.value = '';
            this.$refs.messageInput.$refs.input.value = '';
          }, (error) => {
            console.log('FAILED...', error);
          });
      }
    },
  },
};
</script>

<style lang='scss' scoped>
#contact-section {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.syscops__container {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.content-wrapper {
  width: 830px;
  height: 550px;
  margin: 0 0 0 90px;
  padding: 20px 20px 20px 80px;
  display: grid;
  grid-template-columns: 1fr auto;

  @media screen and (max-width: 1299px) {
    margin: 0 auto;
    justify-content: center;
  }
}

.content__form {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 38px;

  #drag-drop-capcha {
    margin: -50px 0 0 -50px;
  }
}

@keyframes capchaAnimation {
  0% {
    opacity: 0;
    left: -410px;
  }
  65% {
    opacity: 1;
    left: -210px;
  }
  100% {
    left: -270px;
  }
}

@keyframes capchaAnimation_lg {
  0% {
    opacity: 0;
    left: -410px;
  }
  50% {
    opacity: 1;
    left: -150px;
  }
  100% {
    left: -200px;
  }
}

#drag-drop-capcha {
  /deep/ .draggable {
    .content__left-col--fade & {
      animation: 1.4s capchaAnimation;
      animation-delay: 0.8s;

      @media screen and (max-height: 899px) and (max-width: 1440px) {
        animation: 1.4s capchaAnimation_lg;
        animation-delay: 0.8s;
      }
    }
  }

  /deep/ .draggable.verified {
    .content__left-col--fade & {
      animation: none !important;
    }
  }
}

.content__left-col {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.content__left-col--fade {
  opacity: 1;
  transition-delay: 0.8s;
}

.content__right-col {
  width: 22px;
  display: flex;
  margin: 0 15px 0 0;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
  opacity: 0;
  position: relative;

  .verify-id {
    transition: all 0.4s ease-in-out;
    right: -22px;
  }
}

.content__right-col--fade {
  opacity: 1;
  transition-delay: 0.8s;

  .verify-id {
    right: 10px;
    transition-delay: 0.9s;
  }
}

.title {
  @include sectionTitleStyle($margin: 0 0 5px);
  padding: 10px 0 0;
}

.section-illustration {
  position: absolute;
  top: 50px;
  right: calc(-1 * 853px * 0.4);

  @media screen and (max-width: 1399px) {
    right: calc(-1 * 853px * 0.5);
  }

  @media screen and (max-width: 1299px) {
    right: calc(-1 * 853px * 0.7);
  }
}

.form__fields {
  padding: 0 0 0;
  width: 350px;

  .base-input {
    margin: 0 0 10px;
  }

  .base-button {
    margin: 20px 0 0;
  }
}

.verify-id {
  color: rgba($global-secondary-font-color, 0.25);
  font-family: 'Montserrat-Regular';
  font-size: 18px;
  display: inline-block;
  transform: rotate(-90deg);
  transform-origin: right;
  text-align: center;
  position: absolute;
  top: 0px;
  right: 10px;
  margin: 0;
  width: 514px;
  height: 22px;
}

.copyright-row {
  display: flex;
  align-items: center;
  margin: auto 0 80px 90px;
  width: 830px;

  @media screen and (max-width: 1299px) {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-height: 899px) {
    margin-bottom: 15px;
  }

  .copyright {
    color: $global-secondary-font-color;
    font-size: 11px;
    font-family: 'Montserrat-Regular';
    display: inline-block;
    margin: 0 0 0 auto;
  }
}

.contacts_container {
  display: flex;
  justify-content: space-around;
}
</style>
