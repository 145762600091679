<template>
  <div id='scroll-to-top' @click="handleClick">
    <div class="wrapper">
      <span class="triangle" />
      <span class="circle" />
      <span class="text">
        {{ $t('general.scrollToTop.text') }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScrollToTop',
  props: {},
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {
    handleClick() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang='scss' scoped>
@keyframes text {
  0% {
    left: 0;
  }
  60% {
    left: 20px;
  }
  90% {
    left: 0;
  }
}

@keyframes circle {
  0% {
    left: 0;
  }
  60% {
    left: 20px;
  }
  80% {
    left: 0;
  }
}

@keyframes triangle {
  0% {
    left: 0;
  }
  60% {
    left: 15px;
  }
  70% {
    left: 0;
  }
}

#scroll-to-top {
  transform: rotate(-90deg);
  cursor: pointer;
}

.wrapper {
  display: flex;
  align-items: center;
}

.text {
  font-family: 'Montserrat-Bold';
  font-size: 18px;
  color: $purple;
  display: inline-block;
  margin-left: 20px;
  position: relative;
  animation: text 2s cubic-bezier(0.64, -0.21, 0.35, 1.15) infinite;
}

.triangle {
  position: relative;
  display: inline-block;
  margin: 0 0 0 0;
  animation: triangle 2s cubic-bezier(0.64, -0.21, 0.35, 1.15) infinite;
  margin-right: 10px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 7px 16px 7px;
  border-color: transparent transparent #5527c1 transparent;
  transform: rotate(90deg);
}

.circle {
  position: relative;
  width: 10px;
  height: 10px;
  background-color: $purple-light;
  border-radius: 100%;
  display: inline-block;
  animation: circle 2s cubic-bezier(0.64, -0.21, 0.35, 1.15) infinite;
}
</style>
