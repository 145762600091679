<template>
  <div id='welcome-section' ref="section">
    <div class="syscops__container" >
      <div ref="content"
      :class="[
        'content-wrapper', { 'pos-2': secondPosition }, scrollDirClass]"
        :style="`--winHeight: ${windowHeight}px`">
        <img :class="[secondPosition ?
        'animate__animated animate__fadeOutUp animate__faster'
        : 'animate__animated animate__fadeInDown animate__faster']"
          src="/images/logos/sysCopsWithText.svg" class="logo">
        <div class="title__wrapper">
          <h1
            class="title__text-bordered"
            :class="isVisible ? fadeInContentClass : fadeOutContentClass"
            v-html="$t('sections.welcome.title')" />
          <h1
            class="title__text"
            :class="isVisible ? fadeInContentClass : fadeOutContentClass"
            v-html="$t('sections.welcome.title')"
          />
        </div>
        <div :class="isVisible ? fadeInContentClass : fadeOutContentClass">
          <div
            class="description"
            v-html="$t('sections.welcome.description')"
          />
          <base-button
            :title="$t('sections.welcome.button.text')"
            @clicked="handleBtnClick"
          />
        </div>
      </div>
      <img class="section-illustration"
        :class="[
          isVisible ? fadeInIllustrationClass : fadeOutIllustrationClass,
          { 'pos-2': secondPosition }
        ]"
        src="/images/section-illustrations/welcome_illustration.png">
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/shared/BaseButton.vue';

export default {
  name: 'WelcomeSection',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  data: () => ({
    secondPosition: undefined,
    windowHeight: 0,
    scrollCount: 0,
    scrollDownCounter: 0,
    fadeInContentClass: 'animate__animated animate__fadeInDown animate__slow',
    fadeOutContentClass: 'animate__animated animate__fadeOutUp',
    fadeInIllustrationClass: 'animate__animated animate__fadeInDown',
    fadeOutIllustrationClass: 'animate__animated animate__fadeOut animate__faster',
  }),
  created() {
    this.$nextTick(() => {
      this.windowHeight = window.innerHeight;
      this.$refs.section.addEventListener('wheel', this.handleScroll);
    });
  },
  computed: {
    scrollDirClass() {
      if (this.secondPosition !== undefined) {
        return this.secondPosition ? 'down' : 'up';
      }
      return undefined;
    },
  },
  methods: {
    handleScroll(e) {
      if (
        (e.deltaY < 0 && this.secondPosition)
        || (e.deltaY > 0 && !this.secondPosition)
      ) {
        this.scrollCount = 0;
      }

      if (this.scrollCount === 0) {
        e.preventDefault();

        if (e.deltaY < 0) {
          this.scrollCount = 1;
          this.scrollDownCounter = 0;
          this.secondPosition = false;
        } else if (e.deltaY > 0) {
          this.scrollCount = 1;
          this.scrollDownCounter = this.scrollDownCounter === 0 ? 1 : this.scrollDownCounter;
          this.secondPosition = true;

          setTimeout(() => {
            this.scrollCount = 0;
            this.scrollDownCounter += 1;
          }, 1000);

          if (this.scrollDownCounter === 2) {
            this.$emit('switchToNextSection');
            this.scrollDownCounter = 0;
          }
        }

        this.$eventBus.$emit('welcomeSectionScrolled', this.secondPosition);
      }
    },
    handleBtnClick() {
      this.$emit('buttonClicked');
    },
  },
  beforeDestroy() {
    this.$refs.section.removeEventListener('wheel', this.handleScroll);
  },
};
</script>

<style lang='scss' scoped>
#welcome-section {
  height: 100%;
  position: relative;
}

.content-wrapper {
  position: relative;
  margin-top: 20px;
  padding: 70px 0 80px 90px;
  height: 610px; // content box height - 2 * 20px border
  overflow: hidden;

  @media screen and (max-height: 899px) {
    height: calc(var(--winHeight) - 120px - 90px);
    padding-bottom: 0;
  }

  @media screen and (max-height: 759px) {
    height: calc(var(--winHeight) - 120px - 60px);
  }
}

.logo {
  width: 300px;
  height: 73px;
  object-fit: cover;
  position: relative;
  top: 0;
  transition: all 1.5s ease-in-out;

  .pos-2 & {
    top: -50%;
  }
}

.section-illustration {
  position: absolute;
  top: 10px;
  right: -90px;
  width: 905px;
  height: 590px;
  transition: top 0.5s ease-in-out;

  @media screen and (max-height: 759px) {
    height: 500px;
    width: auto;
  }

  @media screen and (max-width: 1399px) {
    width: 800px;
    height: auto;
    right: -30px;
  }

  @media screen and (max-width: 1299px) {
    width: 900px;
    right: -31%;
  }

  &.pos-2 {
    top: -5px;
  }
}

.title__wrapper {
  position: relative;
  top: 0;
  transition: all 1s ease-in-out;
  margin-bottom: 1000px;

  @media screen and (max-width: 1399px) {
    top: -50px;
  }

  .pos-2 & {
    top: -170px;
    margin-bottom: -225px;

    @media screen and (max-height: 899px) {
      top: -200px;
      margin-bottom: -255px;
    }

    @media screen and (max-height: 759px) {
      top: -160px;
      margin-bottom: -225px;
    }
  }
}

@keyframes bordered-title-scroll {
  0% {
    top: 0;
  }
  50% {
    top: 10px;
  }
  100% {
    top: 0;
  }
}

@keyframes bordered-title-scroll-2 {
  0% {
    top: 0;
  }
  50% {
    top: -10px;
  }
  100% {
    top: 0;
  }
}

.title__text {
  font-size: 72px;
  letter-spacing: 0;
  line-height: 1;
  margin: 100px 0 85px;
  font-family: 'AkzidenzGrotesk-Bold';
  position: relative;

  @media screen and (max-height: 759px) {
    font-size: 55px;
    margin-top: 50px;
  }

  @media screen and (max-width: 1299px) {
    font-size: 60px;
  }

  &-bordered {
    font-size: 72px;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
    font-family: 'AkzidenzGrotesk-Bold';
    color: rgba(0, 0, 0, 0.0);
    font-size: 72px;
    text-shadow: -1px 0 $white, 0 1px $white, 1px 0 $white, 0 -1px $white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    @media screen and (max-height: 759px) {
      font-size: 55px;
    }

    @media screen and (max-width: 1299px) {
      font-size: 60px;
    }

    .down & {
      animation: bordered-title-scroll 1.5s;
    }

    .up & {
      animation: bordered-title-scroll-2 1.5s;
    }

    @supports((text-stroke: 2px $white) or (-webkit-text-stroke: 2px $white)) {
      color: transparent;
      -webkit-text-stroke: 2px $white;
      text-stroke: 2px $white;
      text-shadow: none;
    }
  }
}

.description {
  max-width: 440px;
  transition: $global-transition;

  /deep/ p {
    font-family: 'Montserrat-Regular';
    line-height: 28px;
    font-size: 18px;
    margin: 0 0 20px;

    @media screen and (max-height: 759px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.base-button {
  margin: 20px 0 0;
}
</style>
